import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intranet-wallet-burner"
export default class extends Controller {
  connect() {
    const barcode = document.getElementById('barcode');
    barcode.focus();

    const walletBurnerForm = document.getElementById('wallet-burner-form');

    walletBurnerForm.addEventListener('submit', (event) => {
      barcode.value = barcode.value.replace(/\+/g, '');
    });

    if (!document.keydownEventAdded) {
      document.addEventListener('keydown', (event) => {
        if (event.key === '+') {
          event.preventDefault();
          document.getElementById('barcode').focus();
        }
      });

      document.keydownEventAdded = true;
    }
  }

  resetWallet() {
    document.getElementById("current-wallet").innerHTML = "";
    document.getElementById("capabilities").innerHTML = "";
    document.getElementById("submit-burn").innerHTML = "";
    document.getElementById("notify-box").innerHTML = "";
    document.getElementById("barcode").value = "";
    document.getElementById("barcode").focus();
  }

  chooseCapability(event) {
    const button = document.getElementById("use-wallet-button");
    const capability = event.target.closest(".card");
    const capabilities = document.getElementsByClassName("capability-card");

    capabilities.forEach((elem) => elem.classList.remove("border-success"));

    capability.classList.add("border-success");
    button.classList.remove("disabled");

    document.getElementById("capability-id").value = event.params.id;
  }
}
