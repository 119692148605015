import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

  }

  toggleLogFilters(event) {
    const walletLogsFilters = document.getElementById("wallet-logs-filters");
    const checkbox = event.currentTarget;

    if (checkbox.checked) {
      walletLogsFilters.classList.remove("d-none");
    } else {
      walletLogsFilters.classList.add("d-none");
    }
  }

  toggleRefundLine(event) {
    const transactionLine = event.currentTarget.closest("tr");
    const buttonInnerIcon = event.currentTarget.querySelector("i");

    const burnLineId = event.currentTarget.dataset.burnLine;
    const burnLine = document.getElementById(burnLineId);

    const refundLineId = event.currentTarget.dataset.refundLine;
    const refundLine = document.getElementById(refundLineId);

    if (!burnLine) return;

    document.querySelectorAll(".transaction-line.table-info").forEach(line => {
      line.classList.remove("table-info");

      const buttonIcon = line.querySelector("button > i");

      buttonIcon.classList.remove("mdi-chevron-up");
      buttonIcon.classList.add("mdi-chevron-down");
    });

    document.querySelectorAll(".burn-line.table-success").forEach(line => {
      if (line.id !== burnLineId && !line.classList.contains("d-none")) {
        line.classList.add("d-none");
        line.classList.remove("table-success");
      }
    });

    document.querySelectorAll(".refund-line").forEach(line => {
      if (line.id !== refundLineId && !line.classList.contains("d-none")) {
        line.classList.add("d-none");
        line.classList.remove("table-danger");
      }
    });

    if (burnLine.classList.contains("d-none")) {
      burnLine.classList.remove("d-none");
      burnLine.classList.add("table-success");

      transactionLine.classList.add("table-info");

      buttonInnerIcon.classList.remove("mdi-chevron-down");
      buttonInnerIcon.classList.add("mdi-chevron-up");
    } else {
      burnLine.classList.add("d-none");

      transactionLine.classList.remove("table-info");

      buttonInnerIcon.classList.remove("mdi-chevron-up");
      buttonInnerIcon.classList.add("mdi-chevron-down");
    }

    if (!refundLine) return;

    if (refundLine.classList.contains("d-none")) {
      refundLine.classList.remove("d-none");
      refundLine.classList.add("table-danger");
    } else {
      refundLine.classList.add("d-none");
    }
  }
}
